<template>
  <v-form
    :lazy-validation="false"
    @submit.prevent="handleSubmit"
    v-model="valid"
    ref="form"
  >
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t("script.create") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch
          class="white--text pt-6"
          v-model="enabled"
          :label="$t('script.fields.enabled')"
        ></v-switch>
      </v-toolbar>

      <v-card-text>
        <v-text-field
          :label="$t('script.fields.name')"
          name="name"
          prepend-icon="mdi-domain"
          type="text"
          v-model="name"
          :rules="nameRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-select
          v-model="type"
          :items="typeItems"
          :label="$t('script.fields.type')"
          prepend-icon="mdi-numeric"
          item-text="name"
          item-value="value"
        ></v-select>
      </v-card-text>

      <v-card-text v-if="type == 0">
        <v-text-field
          :label="$t('script.fields.interval')"
          name="interval"
          prepend-icon="mdi-refresh"
          type="text"
          v-model="interval"
          :rules="intervalRules"
        ></v-text-field>
      </v-card-text>

      <div v-if="type == 2">
        <v-card-text>
          <v-select
            v-model="deveui"
            :items="tags"
            :loading="tagStatus.loading"
            :label="$t('alarm.fields.tag')"
            prepend-icon="mdi-cellphone-link"
            item-text="name"
            item-value="deveui"
            :rules="reqRule"
          ></v-select>
        </v-card-text>

        <v-card-text>
          <v-autocomplete
            :disabled="deveui == null"
            :loading="tagDataStatus.loading"
            :label="$t('alarm.fields.tagDataKey')"
            v-model="tagKey"
            :items="tagKeys"
            :rules="reqRule"
            item-text="key"
            item-value="key"
            prepend-icon="mdi-key"
          ></v-autocomplete>
        </v-card-text>
      </div>

      <v-card-text>
        <v-alert type="error" v-if="!validScript">{{ errorMessage }}</v-alert>
        <codemirror
          ref="codeMirror"
          v-model="content"
          :options="cmOptions"
        ></codemirror>
      </v-card-text>

      <v-card-text>
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on">
              {{ $t("script.helpTitle") }}
            </v-btn>
          </template>

          <ExampleScripts v-on:dialog-toggle="dialog = !dialog" />
        </v-dialog>
      </v-card-text>

      <v-card-text class="md-0 pa-0">
        <v-toolbar flat color="primary">
          <v-toolbar-title class="white--text">{{
            $t("script.tagsTitle")
          }}</v-toolbar-title>
        </v-toolbar>
        <v-text-field
          v-model="search"
          :label="$t('common.search')"
          class="mx-2"
        ></v-text-field>
        <v-data-table
          :items-per-page="5"
          class="elevation-1"
          @item-expanded="getKeysForExpanded"
          :items="tags"
          item-key="deveui"
          :headers="headers"
          :single-expand="true"
          show-expand
          :search="search"
        >
          <template v-slot:expanded-item="">
            <td :colspan="2">
              <v-list>
                <v-list-item v-for="(item, i) in expandedKeys" :key="i">
                  <v-list-item-icon>
                    <v-icon> mdi-circle-small </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.key }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </td>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-btn block color="primary" x-large @click="handleSubmit">{{
          $t("common.add")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ExampleScripts from "@/components/script/ExampleDialog";

import { codemirror } from "vue-codemirror";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";

export default {
  name: "ScriptCreate",
  data() {
    return {
      search: "",
      submitted: false,
      valid: false,
      dialog: false,

      name: "",
      type: 0,
      interval: 10,
      deveui: null,
      tagKey: null,
      tagKeys: [],
      expandedKeys: [],
      enabled: true,
      content: this.$t("script.placeHolderContent"),

      typeItems: [
        {
          name: this.$t("script.type.0"),
          value: 0,
        },
        {
          name: this.$t("script.type.1"),
          value: 1,
        },
        {
          name: this.$t("script.type.2"),
          value: 2,
        },
      ],

      nameRules: [
        (v) => !!v || this.$t("alarm.validation.nameRequired"),
        (v) => (v && v.length <= 255) || this.$t("alarm.validation.max"),
      ],

      intervalRules: [
        (v) => !!v || this.$t("script.validation.intervalRequired"),
        (v) => v >= 0 || this.$t("script.validation.intervalMinValue"),
      ],

      reqRule: [
        (v) => !!v || v === 0 || this.$t("alarm.validation.nameRequired"),
      ],

      headers: [
        { text: this.$t("tag.fields.deveui"), value: "deveui" },
        { text: this.$t("tag.fields.name"), value: "name" },
      ],

      cmOptions: {
        tabSize: 4,
        mode: "javascript",
        theme: "monokai",
        lineNumbers: true,
        line: true,
      },
    };
  },

  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
    ...mapState("tag", { tagStatus: "status", tags: "tags" }),
    ...mapState("tagData", { tagDataStatus: "status", keys: "keys" }),
    ...mapState("companies", {
      companyUsers: "companyUsers",
      companyStatus: "status",
    }),
    ...mapState("scripts", {
      errorMessage: "error",
      validScript: "validScript",
    }),
  },

  methods: {
    ...mapActions("scripts", ["create", "test", "resetValidScript"]),
    ...mapActions("tag", ["getTags", "getKeysForTagAsync"]),

    // eslint-disable-next-line
    async handleSubmit(e) {
      const { name, type, interval, content, enabled, deveui, tagKey } = this;
      var payload = {
        name: name,
        type: type,
        interval: interval,
        content: content,
        enabled: enabled,
        tagDeveui: deveui,
        key: tagKey,
      };

      await this.test({ payload: { content: content } });

      if (!this.$refs.form.validate() || !this.validScript) {
        this.valid = false;
        window.scrollTo(0, 0);
        return;
      }

      this.valid = true;
      this.submitted = true;

      if (this.valid) {
        this.create({ payload: payload });
        this.$router.push("/script");
      }
    },

    async getKeysForExpanded(param) {
      this.expandedKeys = await this.getKeysForTagAsync({
        tagId: param.item.deveui,
      });
    },
  },

  async created() {
    await this.resetValidScript();
    await this.getTags();

    this.$refs.codeMirror.codemirror.setSize(null, 500);
  },

  components: {
    ExampleScripts,
    codemirror,
  },

  watch: {
    async deveui(val) {
      this.tagKeys = await this.getKeysForTagAsync({ tagId: val });
      this.tagKey = null;
    },
  },
};
</script>

<style scoped>
.white--text /deep/ label {
  color: white;
}
</style>
